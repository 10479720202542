import { IonImg, IonText } from "@ionic/react";
import axios from "axios";
import React, { memo, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ItemHorizontalFlashsale from "./url/ItemHorizontalFlashsale";

interface GridProps {
  info: any;
  scrollTo: any;
}
declare const window: any;
const ItemTabList: React.FC<GridProps> = ({ info, scrollTo }) => {
  const [tabs, setTabs] = useState<any>();

  let refs = useRef<any>([]);

  React.useEffect(() => {
    axios
      .get(info.api)
      .then((response) => response.data)
      .then((data) => {
        setTabs(data);
      });
  }, [info]);
  return (
    <>
      <div className="sticky" style={{ backgroundColor: "#fff" }}>
        <Slider
          lazyLoad="progressive"
          centerPadding="30px"
          dots={false}
          infinite={false}
          speed={300}
          slidesToShow={5.5}
          focusOnSelect={true}
          slidesToScroll={5.5}
          responsive={[
            {
              breakpoint: 600,
              settings: { slidesToShow: 5.5, slidesToScroll: 5.5 },
            },
            {
              breakpoint: 1000,
              settings: { slidesToShow: 6.5, slidesToScroll: 6.5 },
            },
            {
              breakpoint: 2000,
              settings: { slidesToShow: 8.5, slidesToScroll: 8.5 },
            },
          ]}
        >
          {tabs &&
            tabs.map((i: any, index: any) => (
              <IonImg
                src={i.tab_pic}
                key={`tab${i.tab_id}`}
                onClick={() => {
                  // @ts-ignore
                  scrollTo(refs.current[index].offsetTop);
                  // window.scrollToTop();
                }}
              />
            ))}
        </Slider>
      </div>
      {tabs &&
        tabs.map((i: any, index: number) => (
          <div
            style={{ borderWidth: 2, borderColor: "red" }}
            key={`items${i.tab_id}`}
            ref={(ref) => (refs.current[index] = ref)}
          >
            <div
              style={{
                width: "40%",
                paddingRight: 16,
                paddingLeft: 16,
                backgroundColor: info.color1,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
              }}
            >
              <IonText
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: "#eee",
                }}
              >
                {i.tab_label}
              </IonText>
            </div>
            <ItemHorizontalFlashsale reverse={true} info={i} />
          </div>
        ))}
    </>
  );
};

export default memo(ItemTabList);
