import { RefresherEventDetail } from "@ionic/core";
import {
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSkeletonText,
  useIonViewDidEnter,
} from "@ionic/react";
import axios from "axios";
import { arrowUpOutline, chevronDownOutline } from "ionicons/icons";
import React, {
  lazy,
  Suspense,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Img } from "react-image";
import {
  LazyLoadComponent,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "./Page.css";
const ItemGrid = lazy(() => import("../components/ItemGrid"));
const ItemSlide = lazy(() => import("../components/ItemSlide"));
const CategoryGrid = lazy(() => import("../components/CategoryGrid"));
const Carousel = lazy(() => import("../components/Carousel"));
const Title = lazy(() => import("../components/Title"));
const Countdown = lazy(() => import("../components/Countdown"));
const ItemHorizontalFlashsale = lazy(
  () => import("../components/ItemHorizontalFlashsale")
);
const More = lazy(() => import("../components/More"));
const ArticleSlide = lazy(() => import("../components/ArticleSlide"));
const ItemInfinite = lazy(() => import("../components/ItemInfinite"));

interface PageProps {
  match: any;
  history: any;
  scrollPosition: any;
}
declare const window: any;

const Url2: React.FC<PageProps> = ({ match, history, scrollPosition }) => {
  const [loading, setLoading] = useState<any>(true);
  const contentRef = useRef(null);
  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current.scrollToTop("300");
  };
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const [comps, setComps] = useState<any>([]);
  function onRefresh(event: CustomEvent<RefresherEventDetail>) {
    getComps(match.params.url);
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  const getComps = async (url: any) => {
    await axios
      .get("https://api.harnic.id/page/url/" + url)
      .then((response) => response.data)
      .then((data) => {
        setComps(data);
      });
  };

  const contentOrigin = (url: any) => {
    if (url.includes("https://assets.harnicid.com/page_content/")) {
      let file = url.replace("https://assets.harnicid.com/page_content/", "");
      let origin = file.replace(".webp", "");
      return `https://assets.harnicid.com/page_content/jpg/${origin}`;
    } else {
      return url;
    }
  };

  const sendToRN = (type: any, id: any) => {
    let param = {
      type: type,
      id: id,
    };
    console.log("param_send", param);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(param));
    } else {
      switch (type) {
        case "goToDetail":
          window.location.href = "https://m.harnic.id/product/" + id;
          break;
        case "goToSearch":
          window.location.href = "https://harnic.id/product/filter/" + id;
          break;
        case "goToBrand":
          window.location.href = "https://harnic.id/product/brand/" + id;
          break;
        default:
          break;
      }
    }
  };

  useIonViewDidEnter(() => {
    getComps(match.params.url);
  }, [match.params.url]);

  useLayoutEffect(() => {
    setLoading(false);
  }, []);

  return (
    <IonPage id="url">
      <IonContent ref={contentRef} scrollEvents={true} fullscreen>
        {loading && <IonProgressBar type="indeterminate" />}
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="lines"
          ></IonRefresherContent>
        </IonRefresher>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="3" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="6" className="p-0 m-0 justify-content-center ">
              {comps.map((i: any) => (
                <LazyLoadComponent key={i.row_id}>
                  <Suspense
                    fallback={
                      <IonSkeletonText animated style={{ height: 300 }} />
                    }
                  >
                    {i.component_type === "ITEM_GRID3" ? (
                      <ItemGrid col={4} info={i} />
                    ) : i.component_type === "ITEM_GRID2" ? (
                      <ItemGrid col={6} info={i} />
                    ) : i.component_type === "ITEM_HORIZONTAL_FLASHSALE" ? (
                      <ItemHorizontalFlashsale info={i} reverse={false} />
                    ) : i.component_type ===
                      "ITEM_HORIZONTAL_FLASHSALE_REVERSE" ? (
                      <ItemHorizontalFlashsale info={i} reverse={true} />
                    ) : i.component_type === "ITEM_INFINITE" ? (
                      <ItemInfinite info={i} />
                    ) : i.component_type === "ITEM_SLIDE" ? (
                      <ItemSlide info={i} />
                    ) : i.component_type === "ARTICLE_SLIDE" ? (
                      <ArticleSlide info={i} history={history} />
                    ) : i.component_type === "CATEGORIES_GRID4" ? (
                      <CategoryGrid col={3} info={i} history={history} />
                    ) : i.component_type === "CATEGORIES_GRID2" ? (
                      <CategoryGrid col={6} info={i} history={history} />
                    ) : i.component_type === "CATEGORIES_GRID3" ? (
                      <CategoryGrid col={4} info={i} history={history} />
                    ) : i.component_type === "CATEGORIES_GRID5" ? (
                      <CategoryGrid col={2.4} info={i} history={history} />
                    ) : i.component_type === "BANNER" ? (
                      <Img
                        onClick={() =>
                          sendToRN(
                            i.gotoscreen === "product"
                              ? "goToDetail"
                              : i.gotoscreen === "category"
                              ? "goToSearch"
                              : i.gotoscreen === "brand"
                              ? "goToBrand"
                              : "",
                            i.api2
                          )
                        }
                        src={isChrome ? i.api : contentOrigin(i.api)}
                        loader={
                          <IonSkeletonText animated className="ratio-41" />
                        }
                        unloader={
                          <img
                            src={require("../assets/images/default-banner.png")}
                            alt="no-pic"
                            width="100%"
                          />
                        }
                      />
                    ) : i.component_type === "CAROUSEL" ? (
                      <Carousel info={i} history={history} />
                    ) : i.component_type === "TITLE" ? (
                      <Title info={i} />
                    ) : i.component_type === "MORE" ? (
                      <More info={i} history={history} />
                    ) : i.component_type === "COUNTDOWN" ? (
                      <Countdown info={i} />
                    ) : (
                      <span></span>
                    )}
                  </Suspense>
                </LazyLoadComponent>
              ))}
            </IonCol>
            <IonCol sizeLg="3" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => scrollToTop()}>
            <IonIcon icon={arrowUpOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default trackWindowScroll(Url2);
