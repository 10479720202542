import {
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonThumbnail,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useEffect, useState } from "react";
import StarRatingComponent from "react-star-rating-component";
import "./Comps.css";

interface ItemReviewProps {
  item: any;
  trx: any;
}

const ItemReview: React.FC<ItemReviewProps> = ({ item, trx }) => {
  const [isDone, setIsDone] = useState<any>(false);
  const [rating, setrating] = useState<any>(5);
  const [comment, setcomment] = useState<any>("");
  const send = () => {
    axios
      .post(
        `https://api.harnic.id/product/sendReviewBeta/${trx.ec_user_id}`,
        qs.stringify({
          item_id: item.itemid,
          rating: rating,
          comment: comment,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        setIsDone(true);
        console.log(res);
      });
  };
  useEffect(() => {
    console.log(item);
    if (item.rating) {
      setcomment(item.comment_body);
      setrating(item.rating);
      setIsDone(true);
    }
  }, [item]);
  return (
    <IonItem key={item.itemid.toString()}>
      <IonThumbnail slot="start">
        <IonImg src={item.pic_url} />
      </IonThumbnail>
      <IonLabel className="ion-text-wrap">
        <IonText style={{ fontSize: 12, wordWrap: "break-word" }}>
          {item.online_name}
        </IonText>
        <br />
        <StarRatingComponent editing name="rate" starCount={5} value={rating} />
        <br />
        <IonTextarea
          required
          readonly={isDone}
          style={{
            padding: 4,
            backgroundColor: !isDone ? "#eee" : "#ddd",
            color: "#333",
            borderRadius: 4,
          }}
          onIonChange={(e) => {
            setcomment(e.detail.value);
          }}
          value={comment}
        ></IonTextarea>
        <IonButton
          disabled={isDone || !comment}
          color={!isDone ? "primary" : "light"}
          onClick={() => send()}
        >
          {!isDone ? "Kirim" : "Terimakasih"}
        </IonButton>
      </IonLabel>
    </IonItem>
  );
};

export default memo(ItemReview);
