import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import Article from "./pages/Article";
import DeliverySupport from "./pages/DeliverySupport";
/* Theme Pages */
import NotFound from "./pages/NotFound";
import Payment from "./pages/Payment";
import Transaction from "./pages/Transaction";
import Url from "./pages/Url";
import Url2 from "./pages/Url2";
/* Theme variables */
import "./theme/variables.css";

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/" render={() => <Redirect to="/notfound" />} />
        <Route path="/notfound/" component={NotFound} exact={true} />
        <Route path="/url/:url" component={Url} exact={true} />
        <Route path="/url2/:url" component={Url2} exact={true} />
        <Route
          path="/deliverysupport"
          component={DeliverySupport}
          exact={true}
        />
        <Route
          path="/transaction/:user/:trx"
          component={Transaction}
          exact={true}
        />
        <Route
          path="/transaction/:user/:trx/payment/:method"
          component={Payment}
          exact={true}
        />
        <Route path="/article/:id" component={Article} exact={true} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
