import {
  IonCard,
  IonCol,
  IonGrid,
  IonImg,
  IonRippleEffect,
  IonRow,
} from "@ionic/react";
import React, { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import ProgressBar from "../ProgressBar";

interface GridProps {
  info: any;
  reverse: boolean;
}
declare const window: any;

const ItemHorizontalFlashsale: React.FC<GridProps> = ({ info, reverse }) => {
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  const sendToRN = (type: any, id: any) => {
    let param = {
      type: type,
      id: id,
    };
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(param));
    } else {
      window.location.href = "https://m.harnic.id/product/" + id;
    }
  };

  return (
    <div
      style={{
        backgroundColor: info.color1,
      }}
    >
      <IonGrid>
        <IonRow>
          {info.items.map((i: any) => (
            <IonCol key={i.itemid.toString()} size="12" sizeMd="6">
              <div className="ion-activatable ripple-parent">
                <IonCard
                  className="m-2"
                  onClick={() => sendToRN("goToDetail", i.itemid)}
                >
                  {i.stock_loc === "0" && (
                    <div
                      className="ion-justify-content-center ion-align-items-center"
                      style={{
                        position: "absolute",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        width: "100%",
                        height: "100%",
                        zIndex: 3,
                        paddingTop: "30%",
                      }}
                    >
                      <IonImg
                        src={require("../../assets/images/habis.png")}
                        className="absolute-center"
                        style={{ padding: "5%" }}
                      />
                    </div>
                  )}
                  <IonRippleEffect></IonRippleEffect>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="3" size-sm>
                        <div>
                          <LazyLoadImage
                            placeholder={<Skeleton width="100%" height={150} />}
                            src={isChrome ? i.pic_url : i.pic_url_ios}
                            width="100%"
                            height="100%"
                          />
                          {i.info && (
                            <div className="ribbon-product">{i.info}</div>
                          )}
                        </div>
                      </IonCol>
                      <IonCol size="9" size-sm>
                        <div className="product-caption">
                          <p className="product-name" style={{ marginTop: 0 }}>
                            {i.online_name}
                          </p>
                          <span className="price-regular">
                            <NumberFormat
                              value={i.price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp. "}
                              style={{ marginRight: 16 }}
                            />
                          </span>
                          {i.price_old > 0 && (
                            <span className="price">
                              <NumberFormat
                                value={i.price_old}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp. "}
                                style={{
                                  textDecoration: "line-through",
                                  fontSize: 11,
                                }}
                              />
                            </span>
                          )}
                        </div>
                        {i.max_stock && (
                          <ProgressBar
                            stock={i.stock_loc}
                            full={i.max_stock || 50}
                            reverse={reverse}
                          />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              </div>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default memo(ItemHorizontalFlashsale);
