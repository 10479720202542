import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
    IonPage,
    IonRippleEffect,
    IonRow,
    IonText,
    IonToast,
    IonToolbar,
} from "@ionic/react";
import axios from "axios";
import { checkmarkCircle, closeCircle } from "ionicons/icons";
import qs from "qs";
import React, { memo, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import { contentOrigin } from "../helpers/contentOrigin";
import "./Page.css";

interface DeliverySupportProps extends RouteComponentProps<{ props: any }> {}
declare const window: any;

const DeliverySupport: React.FC<DeliverySupportProps> = (props: any) => {
    const isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);

    const [cekModal, setCekModal] = useState(false);
    const [progress, setProgress] = useState<any>(false);
    const [showToast, setShowToast] = useState<any>(null);
    const [toastMsg, setToastMsg] = useState<any>(null);
    const [shippingSupport, setShippingSupport] = useState<any>([]);
    const [zip, setZip] = useState<any>(null);

    const getShippingSupport = async () => {
        setProgress(true);
        axios
            .post(`${API}/shipping/support/zip`, qs.stringify({ zip }), {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                },
            })
            .then((res) => {
                setProgress(false);
                if (res.data.status === true) {
                    setCekModal(true);
                    setShippingSupport(res.data);
                } else {
                    setCekModal(false);
                    setToastMsg("Area Tidak disupport");
                    setShowToast(true);
                }
            })
            .catch((err) => {
                setProgress(false);
                setToastMsg(err);
                setShowToast(true);
            });
    };

    const contentRef = useRef(null);
    const scrollToTop = () => {
        // @ts-ignore
        contentRef.current.scrollToTop("300");
    };

    return (
        <IonPage id="deliverysupport">
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMsg}
                color="dark"
                position="top"
                duration={2000}
            />
            <IonLoading
                cssClass="my-custom-class"
                isOpen={progress}
                onDidDismiss={() => setProgress(false)}
                message={"Please wait..."}
                duration={5000}
            />
            <IonContent ref={contentRef} scrollEvents={true} fullscreen>
                <IonGrid className="p-0 m-0">
                    <IonRow className="p-0 m-0">
                        <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
                        <IonCol
                            sizeLg="8"
                            className="p-0 m-0 justify-content-center "
                        >
                            <IonGrid>
                                <IonRow
                                    style={{
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                    }}
                                >
                                    <IonCol
                                        size="6"
                                        className="ion-justify-content-center ion-align-items-center ion-text-center"
                                    >
                                        <LazyLoadImage
                                            placeholder={
                                                <Skeleton
                                                    width="auto"
                                                    height={50}
                                                />
                                            }
                                            src={
                                                isChrome
                                                    ? require("../assets/images/cod.png")
                                                    : contentOrigin(
                                                          require("../assets/images/cod.png")
                                                      )
                                            }
                                            width="auto"
                                            height={70}
                                        />
                                    </IonCol>
                                    <IonCol
                                        size="6"
                                        className="ion-justify-content-center ion-align-items-center ion-text-center"
                                    >
                                        <IonRippleEffect></IonRippleEffect>
                                        <LazyLoadImage
                                            placeholder={
                                                <Skeleton
                                                    width="auto"
                                                    height={50}
                                                />
                                            }
                                            src={
                                                isChrome
                                                    ? require("../assets/images/freeongkir.png")
                                                    : contentOrigin(
                                                          require("../assets/images/freeongkir.png")
                                                      )
                                            }
                                            width="auto"
                                            height={70}
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                            <IonList lines="full">
                                <IonListHeader>
                                    <IonLabel>Cek Ongkir</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonLabel position="floating">
                                        Kode Pos
                                    </IonLabel>
                                    <IonInput
                                        type="tel"
                                        value={zip}
                                        onIonChange={(e) => {
                                            setZip(e.detail.value);
                                        }}
                                    ></IonInput>
                                </IonItem>
                                <div className="ion-padding">
                                    <IonButton
                                        expand="block"
                                        color="primary"
                                        type="submit"
                                        className="ion-no-margin"
                                        onClick={() => {
                                            getShippingSupport();
                                        }}
                                    >
                                        <IonText style={{ color: "#FFFFFF" }}>
                                            Cek Area
                                        </IonText>
                                    </IonButton>
                                </div>
                                <IonModal
                                    isOpen={cekModal}
                                    cssClass="modal-sheet-kodepos"
                                    onWillDismiss={() => setCekModal(false)}
                                >
                                    <IonToolbar style={{ paddingTop: 10 }}>
                                        <IonButtons slot="primary">
                                            <IonButton
                                                onClick={() =>
                                                    setCekModal(false)
                                                }
                                            >
                                                <IonIcon
                                                    slot="icon-only"
                                                    color="grey"
                                                    name="close"
                                                />
                                            </IonButton>
                                        </IonButtons>
                                    </IonToolbar>

                                    <IonCol size="12">
                                        <div className="ion-activatable ripple-parent">
                                            {shippingSupport.status === true ? (
                                                <>
                                                    {shippingSupport.data.map(
                                                        (data: any) => (
                                                            <IonList>
                                                                {data.support ===
                                                                "1" ? (
                                                                    <IonItem>
                                                                        <IonLabel className="ion-text-warp">
                                                                            <IonText
                                                                                style={{
                                                                                    paddingLeft: 5,
                                                                                    fontSize: 12,
                                                                                    wordWarp:
                                                                                        "break-word",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    data.kurir
                                                                                }
                                                                            </IonText>
                                                                            <IonGrid>
                                                                                <IonRow className="ion-align-items-center">
                                                                                    <IonIcon
                                                                                        style={{
                                                                                            color:
                                                                                                "#81CA33",
                                                                                            fontSize: 20,
                                                                                        }}
                                                                                        icon={
                                                                                            checkmarkCircle
                                                                                        }
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            marginLeft: 5,
                                                                                            marginRight: 5,
                                                                                        }}
                                                                                    >
                                                                                        <IonText
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                fontWeight:
                                                                                                    "bold",
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                data.jenis
                                                                                            }
                                                                                        </IonText>
                                                                                    </span>
                                                                                </IonRow>
                                                                            </IonGrid>
                                                                            <IonText
                                                                                style={{
                                                                                    paddingLeft: 5,
                                                                                    fontSize: 12,
                                                                                    wordWarp:
                                                                                        "break-word",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    data.tarif
                                                                                }
                                                                            </IonText>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                ) : (
                                                                    <IonItem>
                                                                        <IonLabel className="ion-text-warp">
                                                                            <IonText
                                                                                style={{
                                                                                    paddingLeft: 5,
                                                                                    fontSize: 12,
                                                                                    wordWarp:
                                                                                        "break-word",
                                                                                    color:
                                                                                        "#999",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    data.kurir
                                                                                }
                                                                            </IonText>
                                                                            <IonGrid>
                                                                                <IonRow className="ion-align-items-center">
                                                                                    <IonIcon
                                                                                        style={{
                                                                                            color:
                                                                                                "#999",
                                                                                            fontSize: 20,
                                                                                        }}
                                                                                        icon={
                                                                                            closeCircle
                                                                                        }
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            marginLeft: 5,
                                                                                            marginRight: 5,
                                                                                        }}
                                                                                    >
                                                                                        <IonText
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                fontWeight:
                                                                                                    "bold",
                                                                                                color:
                                                                                                    "#999",
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                data.jenis
                                                                                            }
                                                                                        </IonText>
                                                                                    </span>
                                                                                </IonRow>
                                                                            </IonGrid>
                                                                            <IonText
                                                                                style={{
                                                                                    paddingLeft: 5,
                                                                                    fontSize: 12,
                                                                                    wordWarp:
                                                                                        "break-word",
                                                                                    color:
                                                                                        "#999",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    data.tarif
                                                                                }
                                                                            </IonText>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                )}
                                                            </IonList>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <div
                                                    style={{
                                                        justifyContent:
                                                            "center",
                                                        flex: 1,
                                                        textAlign: "center",
                                                        color: "#252158",
                                                        fontWeight: "bold",
                                                        paddingTop: 20,
                                                    }}
                                                >
                                                    <IonText
                                                        style={{
                                                            textAlign: "center",
                                                            fontSize: 20,
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Area tidak disupport
                                                    </IonText>
                                                    <IonImg
                                                        style={{
                                                            marginLeft: 80,
                                                            marginRight: 80,
                                                        }}
                                                        src={require("../assets/images/warning.png")}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </IonCol>
                                </IonModal>
                            </IonList>

                            <div className="ion-padding">
                                <h2>
                                    <strong>Syarat Dan Ketentuan</strong>
                                </h2>
                                <br />
                                <strong>Cash On Delivery (COD)</strong>
                                <p>
                                    Pembayaran dengan menggunakan metode COD
                                    agar anda merasa aman dan tidak perlu takut
                                    barang tidak sampai atau tidak dikirimkan.
                                    <br />
                                    Dengan ketentuan sebagai berikut:
                                </p>
                                <ol>
                                    <li>
                                        Hanya berlaku dengan pengiriman Harnic
                                        Xpress.
                                    </li>
                                    <li>
                                        Hanya berlaku untuk jadwal pengiriman
                                        Same Day &amp; Next Day.
                                    </li>
                                    <li>
                                        Pembelanjaan dengan maksimum Rp
                                        1.000.000,-.
                                    </li>
                                    <li>
                                        Alamat tujuan hanya mencangkup wilayah
                                        sesuai dengan gratis ongkir.
                                    </li>
                                    <li>
                                        Diusahakan untuk menyediakan uang pas
                                        sesuai dengan nominal transaksi.
                                    </li>
                                </ol>
                                <br />
                                <strong>Gratis Ongkir</strong>
                                <ol>
                                    <li>Wilayah Seluruh Jakarta.</li>
                                    <li>
                                        WIlayah luar Jakarta hanya meliputi
                                        Daerah kota.
                                    </li>
                                    <li>
                                        Bebas ongkir dengan jarak dan berat
                                        berapapun dalam area yang sudah
                                        ditentukan.
                                    </li>
                                    <li>
                                        Pengiriman yang digunakan hanya Harnic
                                        Xpress.
                                    </li>
                                    <li>Minimum transaksi Rp 40.000,-</li>
                                    <li>
                                        Pengiriman mengikuti waktu pengiriman
                                        yang dipilih saat proses checkout.
                                    </li>
                                    <li>
                                        Berlaku untuk pengiriman Same Day &amp;
                                        Next Day.
                                    </li>
                                </ol>
                            </div>
                        </IonCol>
                        <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default memo(DeliverySupport);
