import React, { memo } from "react";

import { IonContent, IonPage } from "@ionic/react";

const NotFound = () => {
  return (
    <IonPage id="NotFound">
      <IonContent fullscreen>
        <h1>Not Found</h1>
      </IonContent>
    </IonPage>
  );
};

export default memo(NotFound);
