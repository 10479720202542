import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonToolbar,
} from "@ionic/react";
import ReactCreditCard from "@repay/react-credit-card";
import "@repay/react-credit-card/dist/react-credit-card.css";
import { FOCUS_TYPE } from "@repay/react-credit-card/dist/ReactCreditCard";
import axios from "axios";
import qs from "qs";
import React, { memo } from "react";
import Iframe from "react-iframe";
import "./Comps.css";
import CreditCard from "./CreditCard";

interface Props {
  info: any;
  onCancel: any;
}
declare const window: any;
const PaymentCC: React.FC<Props> = ({ info, onCancel }) => {
  const [repay, setRepay] = React.useState(0);
  const [cards, setCards] = React.useState([]);
  const [selectedCard, setSelectedCard] = React.useState<any>();
  const [cvv, setCvv] = React.useState<any>(null);
  const [showCVVModal, setShowCVVModal] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [iframeURL, setIframeURL] = React.useState<any>("");
  const [checked, setChecked] = React.useState<any>(false);
  const [values, setValues] = React.useState({
    name: "",
    number: "",
    expiration: "",
    cvc: "",
  });

  React.useEffect(() => {
    if (info.reff_bank !== null) {
      console.log("repay");
      setRepay(1);
    } else {
      console.log("no repay");
      setRepay(0);
      getCC();
    }
  }, [info]);

  const handleChange = React.useCallback(
    (event) => {
      const { name, value } = event.target;
      setValues((v) => ({ ...v, [name]: value }));
    },
    [setValues]
  );

  const [focused, setFocus] = React.useState<FOCUS_TYPE | undefined>(undefined);
  const handleFocus = React.useCallback(
    (event) => setFocus(event.target.name as FOCUS_TYPE),
    [setFocus]
  );
  const handleBlur = React.useCallback(() => setFocus(undefined), [setFocus]);

  const continuePayment = async () => {
    let reff_bank = info.reff_bank.split(";");
    setIframeURL(reff_bank[1]);
    setIsSuccess(true);
    setShowModal(true);
  };
  const submitCC = async (
    cardholder: string,
    cardnumber: string,
    mm: string,
    yy: string,
    cvv: string
  ) => {
    setProcessing(true);
    await axios
      .post(
        `https://api.harnic.id/trx/payWithCC/${info.trxno}/0`,
        qs.stringify({
          card_num: cardholder,
          cardnumber: cardnumber,
          mm: mm,
          yy: yy,
          cvv: cvv,
        })
      )
      .then((res) => {
        setProcessing(false);
        if (res.data.success) {
          console.log("cc res", res.data);
          if (checked) {
            saveCC(cardnumber, cardholder, mm + yy);
          }
          setIframeURL(res.data.data);
          setIsSuccess(true);
          setShowModal(true);
        } else {
          setIsSuccess(false);
          setShowModal(true);
        }
      })
      .catch((e) => {
        setProcessing(false);

        setIsSuccess(false);
        setShowModal(true);
      });
  };

  const saveCC = async (card_num: any, card_holder: any, card_exp: any) => {
    setProcessing(true);
    await axios.post(
      `https://api.harnic.id/user/${info.ec_user_id}/card2`,
      qs.stringify({
        card_num: card_num,
        card_holder: card_holder,
        card_exp: card_exp,
      })
    );
  };
  const getCC = async () => {
    const user_token = localStorage.getItem("user_token");
    setProcessing(true);
    await axios
      .get(`https://api.harnic.id/user/${info.ec_user_id}/card2`)
      .then((res) => {
        setProcessing(false);
        if (res.data) {
          setCards(res.data);
        }
      })
      .catch((e) => {
        setProcessing(false);
      });
  };

  return (
    <>
      {repay ? (
        <>
          <IonCard color="warning">
            <IonCardHeader>
              <IonCardSubtitle>Pembayaran Belum Selesai</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              Silahkan melanjutkan proses pembayaran dengan menekan tombol
              berikut
            </IonCardContent>
          </IonCard>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 4,
              paddingTop: 4,
            }}
          >
            <IonButton
              disabled={processing}
              onClick={() => continuePayment()}
              expand="block"
            >
              {processing ? (
                <IonSpinner name="circular" style={{ alignSelf: "center" }} />
              ) : (
                "LANJUTKAN PEMBAYARAN"
              )}
            </IonButton>
          </div>
        </>
      ) : (
        <>
          {cards.length > 0 && (
            <IonCol>
              <div className="ion-text-center">
                <h3>Pilih Kartu Tersimpan:</h3>
              </div>
            </IonCol>
          )}

          {cards.map((c: any, index: any) => (
            <CreditCard
              key={"card" + index}
              card_num={c.card_num}
              card_holder={c.card_holder}
              card_exp={c.card_exp}
              action={() => {
                console.log(c);
                setSelectedCard(c);
                setShowCVVModal(true);
              }}
            />
          ))}
          {/* <div className="ion-padding">
            <IonButton
              fill="outline"
              size="small"
              expand="block"
              onClick={() => {
                let param = {
                  type: "goToRoute",
                  id: "UserListCard",
                };
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(JSON.stringify(param));
                }
              }}
            >
              Lihat Kartu Tersimpan
            </IonButton>
          </div> */}
          {cards.length > 0 && (
            <IonCol>
              <div className="ion-text-center">
                <h3>Atau, input manual:</h3>
              </div>
            </IonCol>
          )}
          <div style={{ padding: 16 }}>
            <ReactCreditCard {...values} focused={focused} />
          </div>
          <IonList>
            <IonItem>
              <IonInput
                type="number"
                name="number"
                value={values.number}
                placeholder="16 digit nomor kartu"
                onIonChange={handleChange}
                onIonFocus={handleFocus}
                onIonBlur={handleBlur}
                clearInput
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonInput
                type="text"
                name="name"
                value={values.name}
                placeholder="Nama pemegang kartu"
                onIonChange={handleChange}
                onIonFocus={handleFocus}
                onIonBlur={handleBlur}
                clearInput
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonInput
                type="number"
                min="0"
                max="4"
                name="expiration"
                value={values.expiration}
                placeholder="Kadaluarsa MMYY"
                onIonChange={handleChange}
                onIonFocus={handleFocus}
                onIonBlur={handleBlur}
                clearInput
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonInput
                maxlength={3}
                type="password"
                name="cvc"
                value={values.cvc}
                placeholder="CVC"
                onIonChange={handleChange}
                onIonFocus={handleFocus}
                onIonBlur={handleBlur}
                clearInput
              ></IonInput>
            </IonItem>
          </IonList>
          <div
            className="ion-justify-content-center ion-align-items-center"
            style={{
              paddingLeft: 16,
              marginTop: 25,
              marginBottom: 20,
              paddingRight: 16,
            }}
          >
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <IonLabel style={{ paddingLeft: 5 }}>Simpan kartu kredit?</IonLabel>
          </div>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 4,
              paddingTop: 4,
            }}
          >
            {/* <div>{values.name}</div>
        <div>{values.number}</div>
        <div>{values.expiration}</div>
        <div>{values.cvc}</div> */}
            <IonButton
              disabled={
                !values.number ||
                !values.expiration ||
                !values.cvc ||
                !values.name ||
                processing
              }
              onClick={() =>
                submitCC(
                  values.name,
                  values.number,
                  values.expiration.substring(0, 2),
                  values.expiration.substring(2, 4),
                  values.cvc
                )
              }
              expand="block"
            >
              {processing ? (
                <IonSpinner name="circular" style={{ alignSelf: "center" }} />
              ) : (
                "LANJUTKAN"
              )}
            </IonButton>
          </div>
        </>
      )}
      <IonModal isOpen={showModal} cssClass="my-custom-class">
        {isSuccess ? (
          <IonContent>
            <IonListHeader>
              <IonLabel>Complete Payment</IonLabel>
              <IonButton color="danger" onClick={() => onCancel()}>
                Tutup
              </IonButton>
            </IonListHeader>
            {/* <div style={{ padding: 32 }}>
              <IonImg src={require("../assets/images/ok.png")} />
            </div> */}
            <Iframe
              url={iframeURL}
              width="100%"
              height="100%"
              position="relative"
              frameBorder={0}
              styles={{ border: 0 }}
            />
          </IonContent>
        ) : (
          <IonContent>
            <IonListHeader>
              <IonLabel>Payment Failed</IonLabel>
              <IonButton color="danger" onClick={() => onCancel()}>
                Tutup
              </IonButton>
            </IonListHeader>
            <div style={{ padding: 32 }}>
              <IonImg src={require("../assets/images/warning.png")} />
              <IonText className="ion-text-center">
                Pembayaran anda ditolak oleh bank, dana tidak terpotong.
              </IonText>
              <br />
              <IonText className="ion-text-center">
                Silahkan hubungi bank anda
              </IonText>
            </div>
          </IonContent>
        )}
      </IonModal>
      <IonModal isOpen={showCVVModal} cssClass="modal-sheet">
        <IonToolbar>
          <IonListHeader>
            <IonLabel>Masukkan CVV</IonLabel>
          </IonListHeader>
          <IonButtons slot="primary">
            <IonButton onClick={() => setShowCVVModal(false)}>
              <IonIcon slot="icon-only" color="danger" name="close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonList>
          <IonItem lines="none">
            <IonGrid>
              <IonRow className="ion-justify-content-center ion-align-items-center">
                <IonInput
                  maxlength={3}
                  type="password"
                  value={cvv}
                  placeholder="3 digit dibelakang kartu"
                  onIonChange={(e) => setCvv(e.detail.value)}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>
        <div className="ion-padding">
          <IonButton
            disabled={processing}
            color="primary"
            expand="block"
            onClick={() =>
              submitCC(
                selectedCard.card_holder,
                selectedCard.card_num,
                selectedCard.card_exp.substring(0, 2),
                selectedCard.card_exp.substring(2, 4),
                cvv
              )
            }
          >
            {processing ? <IonSpinner name="dots" /> : "LANJUTKAN"}
          </IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default memo(PaymentCC);
